<template>
  <v-dialog v-if="user.scan" v-model="dialog" persistent max-width="290">
    <v-card>
      <v-card-title>Metodo Scan</v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <v-card outlined @click="selectMode(false)">
              <v-card-text class="text-center">
                <v-icon x-large>mdi-camera-outline</v-icon>
                <p class="mb-0 text-button">Camara</p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card outlined @click="selectMode(true)">
              <v-card-text class="text-center">
                <v-icon x-large>mdi-qrcode-scan</v-icon>
                <p class="mb-0 text-button">Escaner</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  data: () => ({
    dialog: false,
  }),

  computed: {
    ...mapState("auth", ["user"])
  },

  methods: {
    selectMode(params) {
      window.localStorage.setItem("scanMode", params);
      this.dialog = false;
    },
  },

  mounted() {
    this.dialog = window.localStorage.getItem("scanMode") === null;
  }
};
</script>