<template>
  <component :is="this.$route.meta.layout || 'div'">
    <router-view />
  </component>
</template>

<script>
export default {
  name: "App",

  methods: {
    updateTheme(event) {
      const isDark = event.matches;
      this.$vuetify.theme.dark = isDark;
      const themeColor = isDark ? "#272727" : "#f5f5f5";
      this.updateStatusBarColor(themeColor, isDark);
    },

    updateStatusBarColor(color, isDark) {
      const metaThemeColor = document.querySelector("meta[name=theme-color]");
      if (metaThemeColor) {
        metaThemeColor.setAttribute("content", color);
      }

      const metaStatusBar = document.querySelector("meta[name=apple-mobile-web-app-status-bar-style]");
      if (metaStatusBar) {
        metaStatusBar.setAttribute("content", isDark ? 'black-translucent' : 'default');
      }
    }
  },

  mounted() {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    this.updateTheme(mediaQuery);
    mediaQuery.addEventListener('change', this.updateTheme);

    this.$store.commit("set_desktop", this.$vuetify.breakpoint.lgAndUp);
  },
};
</script>

<style lang="scss">
@import './sass/overrides.scss';
</style>