<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("auth", ["user", "company"]),
    warning() {
      return this.company.warning;
    },
    disabled() {
      return this.company.disabled;
    },
    showAlert() {
      return this.user.owner && (this.warning || this.disabled);
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  }
}
</script>

<template>
  <v-alert dense prominent :type="disabled ? 'error' : 'warning'" class="rounded-0" v-if="showAlert">

    <p class="mb-0" v-if="warning">¡Atención! Su suscripción ha vencido y está próxima a caducar. Regularícela para
      evitar la cancelación del servicio.</p>

    <p class="mb-0" v-else>Su servicio está suspendido por suscripción vencida. Reactívelo para evitar inconvenientes.
    </p>
  </v-alert>
</template>

<style scoped lang="scss"></style>