<template>
    <v-bottom-sheet :value="value" @input="$emit('input', false)" persistent>
        <v-sheet>

            <v-list-item class="pt-3">
                <v-list-item-avatar :color="data.color">
                    <v-icon class="white--text">{{ data.icon }}</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title class="text-h6">{{ data.status }}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                    <v-btn @click="$emit('input', false)" icon>
                        <v-icon color="grey lighten-1">mdi-close</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>

            <div class="px-4 py-3">
                <v-card outlined>
                    <v-simple-table dense>
                        <template v-slot:default>
                            <tbody v-if="data.data">
                                <tr v-if="data.data">
                                    <td>ID Asistente:</td>
                                    <td class="text-end">{{ data.data.rut }}</td>
                                </tr>
                                <tr v-if="data.data">
                                    <td>Nombre Asistente:</td>
                                    <td class="text-end">{{ data.data.name }}</td>
                                </tr>
                                <tr v-if="data.data.list">
                                    <td>Lista:</td>
                                    <td class="text-end">{{ data.data.list }}</td>
                                </tr>
                                <tr v-if="data.data.sellerName">
                                    <td>Agregado por:</td>
                                    <td class="text-end">{{ data.data.sellerName }}</td>
                                </tr>
                                <tr v-if="data.data.category && !data.data.disabled">
                                    <td>Categoría:</td>
                                    <td class="text-end">{{ data.data.category }}</td>
                                </tr>
                                <tr v-if="data.data.category_benefit && !data.data.disabled">
                                    <td>Beneficio:</td>
                                    <td class="text-end">{{ data.data.category_benefit }}</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td>{{ data.message }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
            </div>

            <v-container v-if="data.data" class="px-4 pb-6">
                <v-row>
                    <v-col>
                        <v-btn :loading="isLoadingReject" :disabled="isLoadingReject" @click="fetchValid(false)" large
                            block>
                            Rechazar
                        </v-btn>
                    </v-col>
                    <v-col>
                        <v-btn :loading="isLoadingAccept" :disabled="isLoadingAccept" color="primary"
                            @click="fetchValid(true)" large block>
                            Ingresar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>
    </v-bottom-sheet>
</template>

<script>
import { validTickets } from "@/api/tickets.js";

export default {
    props: ["value", "data"],

    data: () => ({
        isLoadingReject: false,
        isLoadingAccept: false,
    }),

    methods: {
        async fetchValid(params) {
            try {
                this[`isLoading${params ? 'Accept' : 'Reject'}`] = true;
                await validTickets(this.$route.params.uid, this.data.data.rut, params);
            } catch (error) {
                alert("Error:\nIntenrar nuevamente");
            } finally {
                this.$emit('input', false)
                this[`isLoading${params ? 'Accept' : 'Reject'}`] = false;
            }
        }
    },
}
</script>