<template>
  <v-container>
    <!-- Header -->
    <header class="page-header mb-3">
      <h1 class="text-h5 font-weight-medium">Historial de Eventos</h1>
    </header>

    <v-card flat class="custom-card">
      <!-- Items -->
      <v-list two-line>
        <v-list-item v-for="item in formattedItems" :key="item.uid" class="custom-item"
          :to="{ name: 'MyEvent', params: { uid: item.uid } }">
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium">{{ item.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.formattedDate }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />

      <!-- Paginations -->
      <v-card-actions>
        <div class="d-flex align-center">
          <v-btn icon @click="prevPage" :disabled="page === 1 || loadingPrev" :loading="loadingPrev">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <span class="mx-auto">{{ `${page} / ${pages}` }}</span>
          <v-btn icon @click="nextPage" :disabled="page === pages || loadingNext" :loading="loadingNext">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { db } from "@/firebase"
import { collection, query, where, orderBy, limit, getCountFromServer, getDocs, startAfter, endBefore, limitToLast } from "firebase/firestore"

import { formatFirestoreTimestamp } from "@/api/utilities.js";

export default {
  data: () => ({
    page: 1,
    pages: 0,
    limit: 10,
    total: 0,
    items: [],
    lastVisible: null,
    firstVisible: null,
    loading: false,
    loadingNext: false,
    loadingPrev: false
  }),

  computed: {
    formattedItems() {
      return this.items.map(item => ({ ...item, formattedDate: formatFirestoreTimestamp(item.startDate) }))
    }
  },

  methods: {
    // Función genérica para cargar eventos
    async loadEvents(queryConfig) {
      // const collectionRef = collection(db, `companies/${this.$store.state.auth.user.companyId}/events`);
      const documentSnapshots = await getDocs(queryConfig);

      // Se extraen solo los datos necesarios
      const items = documentSnapshots.docs.map(doc => ({
        uid: doc.id,
        ...doc.data() // Considera usar 'select()' si solo necesitas ciertos campos
      }));

      return {
        items,
        lastVisible: documentSnapshots.docs[documentSnapshots.docs.length - 1],
        firstVisible: documentSnapshots.docs[0]
      };
    },

    // Inicializar eventos con paginación
    async onInit(id) {
      const collectionRef = collection(db, `companies/${id}/events`);

      // Cuenta los eventos (podría optimizarse usando alguna técnica de caché si se usa frecuentemente)
      const countQuery = query(collectionRef, where("finishDate", "<=", new Date()));
      const snapshot = await getCountFromServer(countQuery);
      this.total = snapshot.data().count;
      this.pages = Math.ceil(this.total / this.limit);

      // Consulta optimizada (orden descendente para más recientes primero)
      const firstQuery = query(
        collectionRef,
        where("finishDate", "<=", new Date()),
        orderBy("finishDate", "desc"),
        limit(this.limit)  // Solo trae el número necesario de documentos
      );

      const { items, lastVisible } = await this.loadEvents(firstQuery);

      this.items = items;
      this.lastVisible = lastVisible;
    },

    // Cargar siguiente página de eventos
    async nextPage() {
      if (this.loadingNext) return; // Evitar solicitudes simultáneas
      this.loadingNext = true;

      const nextQuery = query(
        collection(db, `companies/${this.$store.state.auth.user.companyId}/events`),
        where("finishDate", "<=", new Date()),
        orderBy("finishDate", "desc"),
        startAfter(this.lastVisible),
        limit(this.limit)
      );

      const { items, lastVisible, firstVisible } = await this.loadEvents(nextQuery);

      this.items = items;
      this.lastVisible = lastVisible;
      this.firstVisible = firstVisible;
      this.page++;
      this.loadingNext = false;
    },

    // Cargar página anterior de eventos
    async prevPage() {
      if (this.loadingPrev) return; // Evitar solicitudes simultáneas
      this.loadingPrev = true;

      const prevQuery = query(
        collection(db, `companies/${this.$store.state.auth.user.companyId}/events`),
        where("finishDate", "<=", new Date()),
        orderBy("finishDate", "desc"),
        endBefore(this.firstVisible),
        limitToLast(this.limit)
      );

      const { items, lastVisible, firstVisible } = await this.loadEvents(prevQuery);

      this.items = items;
      this.lastVisible = lastVisible;
      this.firstVisible = firstVisible;
      this.page--;
      this.loadingPrev = false;
    }
  },

  async mounted() {
    this.onInit(this.$store.state.auth.user.companyId)
  },
}
</script>

<style lang="css" scoped>
.custom-item:not(:first-child) {
  border-top: 1px solid #c7cddb;
}

.custom-card {
  border: 1px solid #c7cddb;
}
</style>