<template>
    <div class="scan-manual pa-4">
        <v-card class="d-flex" elevation="3">
            <v-text-field label="RUT" v-model="item" flat solo hide-details />
            <v-btn @click="sendData" :disabled="disabled" color="primary" class="rounded-l-0" height="48" large
                depressed>
                Buscar
            </v-btn>
        </v-card>
    </div>
</template>

<script>
import Rut from "rutjs";

export default {
    data: () => ({
        item: "",
        disabled: true,
    }),

    watch: {
        item(params) {
            var rut = new Rut(String(params));
            this.item = rut.getNiceRut(false);
            this.disabled = rut.isValid ? false : true;
        }
    },

    methods: {
        sendData() {
            this.$emit("input", this.item)
            this.item = "";
        }
    }
}
</script>

<style lang="scss" scoped>
.scan-manual {
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    position: absolute;
}
</style>