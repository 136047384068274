<template>
    <v-list dense nav>
        <template v-for="item in items">
            <v-list-item :key="item.title" v-if="allowed(item.auth)" :to="item.to" link>
                <v-list-item-icon>
                    <span v-html="item.icon"></span>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>
    </v-list>
</template>

<script>
import { iconHome, iconHistory, iconVip, iconBlock, iconTeam, iconRocket } from '../../mixins/icons'

export default {
    data: () => ({
        items: [
            { title: 'Inicio', icon: iconRocket, to: { name: "owner-home" }, auth: ["owner"], },
            { title: 'Eventos', icon: iconHome, to: { name: "MyEvents" }, auth: ["seller"], },
            { title: 'Eventos Anteriores', icon: iconHistory, to: { name: "EventsHistory" }, auth: ["seller"] },
            { title: 'Asistentes Especiales', icon: iconVip, to: { name: "attendees-specials" }, auth: ["owner"] },
            { title: 'Asistentes Bloqueados', icon: iconBlock, to: { name: "BlackList" }, auth: ["owner"] },
            { title: 'Staff Empresa', icon: iconTeam, to: { name: "Staff" }, auth: ["owner"] },
        ]
    }),

    // computed: {
    //     isMobile() {
    //         return this.$vuetify.breakpoint.mobile;
    //     },
    // },

    methods: {
        allowed(auth) {
            const role = this.$store.state.auth.user.role
            return auth.includes(role)
        }
    }
}
</script>

<style lang="scss" scoped>
.theme--dark {

    .v-list-item--active {
        background-color: #5542f6;

        &::before {
            display: none;
        }
    }
}
</style>