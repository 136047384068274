import _ from 'lodash'
import moment from 'moment';
import { db } from '@/firebase';
import { collection, query, where, getDocs, doc, getDoc, updateDoc } from "firebase/firestore";

export default {
    namespaced: true,
    state: {
        myEvent: {},
        myEvents: []
    },
    mutations: {
        set_myEvent(state, payload) { state.myEvent = payload },
        set_myEvents(state, payload) { state.myEvents = payload }
    },
    actions: {

        // Get Event
        async getEvent({ rootState }, eventId) {

            const user = rootState.auth.user
            const docRef = doc(db, `/companies/${user.companyId}/events/`, eventId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {

                const data = docSnap.data();
                const list = _.map(data.list, 'name');
                data.lists = _.concat(data.lists, list);

                return _.merge({ uid: docSnap.id }, data)
            } else {
                alert("Evento no Existe");
            }
        },

        async readerEvent({ rootState, commit }, eventId) {

            const user = rootState.auth.user
            const docRef = doc(db, `/companies/${user.companyId}/events/`, eventId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                commit("set_myEvent", _.merge({ uid: docSnap.id }, docSnap.data()))
            } else {
                alert("Evento no Existe");
            }
        },

        async readerEvents({ rootState, commit }) {

            const user = rootState.auth.user;
            const events = [];
            const dateLimit = moment().subtract(1, 'days').format("YYYY-MM-DD"); // Yesterday

            const q = query(collection(db, `companies/${user.companyId}/events`), where("dateStart", ">=", dateLimit));
            const querySnapshot = await getDocs(q);

            querySnapshot.forEach((doc) => {
                events.push(_.merge({ uid: doc.id }, doc.data()))
            });

            commit("set_myEvents", events);
        },

        async updateEventState({ rootState }, payload) {
            const user = rootState.auth.user
            const reference = `/companies/${user.companyId}/events/`;

            const updateStateEvent = doc(db, reference, payload.uid);

            await updateDoc(updateStateEvent, {
                active: payload.active
            });
        },

        async eventsPast({ rootState, commit }) {

            const e = []
            const u = rootState.auth.user;

            const q = query(collection(db, `companies/${u.companyId}/events`), where("finishDate", "<=", new Date()));

            const querySnapshot = await getDocs(q);

            querySnapshot.forEach((doc) => {
                e.push(_.merge({ uid: doc.id }, doc.data()))
            });

            commit("set_myEvents", e);
        }
    },
    getters: {
        eventState: (state) => (active) => {
            return _.filter(state.myEvents, { 'active': active });
        }
    }
}