<template>
  <v-navigation-drawer app right temporary :value="value" :permanent="value"
    :width="!$vuetify.breakpoint.mobile ? 400 : '100%'">
    <!-- Header -->
    <v-toolbar :color="!$vuetify.breakpoint.mobile ? 'transparent' : 'primary'" flat>
      <v-toolbar-title :class="!$vuetify.breakpoint.mobile ? 'text-subtitle-1 | font-weight-medium' : null">
        Crear Lista
      </v-toolbar-title>
    </v-toolbar>
    <v-divider v-if="!$vuetify.breakpoint.mobile" />

    <!-- Content -->
    <v-container class="px-4 pt-6">
      <v-form ref="form">
        <v-text-field label="Nombre Lista" color="accent" v-model="name" :rules="[required]" outlined dense />
        <!--  -->
        <v-row>
          <!-- Date -->
          <v-col cols="6">
            <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="0" :nudge-top="1"
              transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="deadLineDay" label="Fecha Limite" readonly v-bind="attrs" v-on="on"
                  :rules="[required]" outlined dense />
              </template>
              <v-date-picker v-model="deadLineDay" @input="menu = false" no-title v-if="value"
                :min="stringDate(event.startDate)" :max="stringDate(event.finishDate)" />
            </v-menu>
          </v-col>
          <!-- Hour -->
          <v-col>
            <v-text-field dense outlined type="time" :rules="[required]" color="accent" label="Hora Limite"
              v-model="deadLineTime" hide-details />
          </v-col>
        </v-row>
      </v-form>

      <div class="text-end | mt-3">
        <v-btn :disabled="loading" @click="onCancel" class="mr-4" plain>Cancelar</v-btn>
        <v-btn :disabled="loading" :loading="loading" @click="onSave" color="primary">Crear Lista</v-btn>
      </div>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapActions } from "vuex";
import { rules } from "@/assets/rules.js";

export default {
  props: ["value", "event"],

  data: () => ({
    menu: false,
    loading: false,
    required: rules.required,
    name: "",
    deadLineDay: "",
    deadLineTime: "",
  }),

  watch: {
    value() {
      if (this.value) {
        this.deadLineTime = "01:00";
        this.deadLineDay = this.stringDate(this.event.finishDate);
      }
    },
  },


  methods: {
    ...mapActions("lists", ["create"]),

    stringDate(value) {
      var date = value.toDate();
      date = moment(date).format("YYYY-MM-DD");
      return date;
    },

    formatdate(date, time) {
      return new Date(String(date + " " + time).replace(/\s/, "T"));
    },

    onCancel() {
      this.$emit("input", false);
      this.$refs.form.reset();
    },

    async onSave() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        await this.create({
          name: _.capitalize(this.name),
          date: this.formatdate(this.deadLineDay, this.deadLineTime),
          eventId: this.event.uid,
          order: new Date().getTime(),
        });
        this.loading = false;
        this.onCancel();
      }
    },
  },
};
</script>