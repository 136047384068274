<template>
  <v-app>
    <v-container fluid class="fill-height py-0">
      <v-row class="fill-height">
        <!-- Form -->
        <v-col cols="12" md="6" class="d-flex flex-column justify-center">
          <v-container>
            <v-row justify="center">
              <v-col cols="10" md="6">

                <img src="@/assets/img/icon.svg" class="rounded" alt="relevent" width="60">

                <!-- Content -->
                <div class="mb-6 mt-2">
                  <slot />
                </div>

                <!-- Footer -->
                <p class="text--disabled text-caption">
                  @2024. Relevent Derechos Reservados | v{{ version }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-col>

        <!-- Image -->
        <v-col cols="12" md="6" class="primary" v-if="!$vuetify.breakpoint.mobile"></v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    version: process.env.VUE_APP_VERSION
  })
}
</script>