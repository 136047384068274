import store from "@/store";
import { formatFirestoreTimestamp } from "./utilities";
import { db } from '@/firebase';
import { collection, query, where, getDocs, orderBy, doc, updateDoc, writeBatch } from "firebase/firestore";


export const createNewEvent = async (payload) => {
    const eventsPath = `companies/${store.state.auth.user.companyId}/events`;

    try {
        const batch = writeBatch(db);

        const newEventRef = doc(collection(db, eventsPath));
        batch.set(newEventRef, payload);

        const listRef = collection(newEventRef, 'lists');
        const newActionRef = doc(listRef);

        batch.set(newActionRef, { name: "General", order: 1 });

        await batch.commit();
        console.debug('Evento creado exitosamente:', payload);
    } catch (error) {
        throw new Error(error.message || 'Hubo un problema al crear el evento.');
    }
}

export const getEvents = async () => {
    const eventsPath = `companies/${store.state.auth.user.companyId}/events`;

    try {
        const q = query(
            collection(db, eventsPath),
            where("finishDate", ">=", new Date()),
            where("disabled", "==", false),
            orderBy("finishDate", "asc")
        );
        const querySnapshot = await getDocs(q);

        const items = [];
        querySnapshot.forEach((doc) => {
            items.push({ uid: doc.id, ...doc.data(), loading: false });
        });

        return items
    } catch (error) {
        console.error('Error in getEvents:', error)
        throw error
    }
}

export const getEventsByMonth = async (month, year) => {
    const eventsPath = `companies/${store.state.auth.user.companyId}/events`;

    try {
        const startDate = new Date(year, month - 1, 1); // El mes es 0-indexed, por eso restamos 1
        const endDate = new Date(year, month, 0);

        const q = query(collection(db, eventsPath), where("disabled", "==", false), where("startDate", ">=", startDate), where("startDate", "<=", endDate), orderBy("startDate", "desc"));
        const querySnapshot = await getDocs(q);

        const items = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            data.date = `${formatFirestoreTimestamp(data.startDate)} - ${formatFirestoreTimestamp(data.finishDate)}`;
            data.loading = false

            items.push({ uid: doc.id, ...data });
        });

        return items
    } catch (error) {
        console.error('Error in getEvents:', error)
        throw error
    }
}

export const disabledEvent = async (eventId) => {
    const eventsPath = `companies/${store.state.auth.user.companyId}/events`;

    try {
        const eventRef = doc(db, eventsPath, eventId);
        await updateDoc(eventRef, { disabled: true });
    } catch (error) {
        console.error("Error al deshabilitar el evento:", error);
        throw error;
    }
}