<template>
  <v-app id="relevent-layout">
    <AppBar @input="drawer = !drawer" />
    <AppDrawer v-model="drawer" />

    <v-main>
      <AppWarning />
      <AppLoading v-model="isLoading" />
      <slot />
    </v-main>

    <DialogScan />
    <DialogUpdate />
  </v-app>
</template>

<script>
import { getCompanyData } from "@/api/auth";

import AppBar from './components/AppBar.vue';
import AppDrawer from "./components/AppDrawer.vue";
import AppLoading from './components/Loading.vue';
import AppWarning from "./components/Warning.vue"
import DialogScan from "../components/DialogScan.vue";
import DialogUpdate from "./components/DialogUpdate.vue";

export default {
  components: { AppBar, AppDrawer, AppLoading, AppWarning, DialogScan, DialogUpdate },

  data: () => ({
    drawer: null,
    isLoading: false,
  }),

  methods: {
    async getCompany() {
      this.isLoading = true;
      await getCompanyData();
      this.isLoading = false;
    }
  },

  mounted() {
    this.getCompany();
  }
};
</script>