<template>
  <div class="auth--login">
    <header>
      <h1 class="text-h">Bienvenidos</h1>
      <p class="text--secondary">Ingresa con los datos de tu cuenta.</p>
    </header>

    <!-- Form -->
    <v-form :disabled="loading" ref="form" @submit.prevent class="mt-9">
      <v-text-field label="Email" flat outlined :rules="emailR" v-model="email" />
      <v-text-field label="Contraseña" flat autocomplete outlined v-model="password" type="password"
        :rules="required" />
      <v-checkbox v-model="checkbox" class="mt-0" label="Recordarme" />

      <v-btn block large :disabled="loading" :loading="loading" depressed color="primary" @click="onSubmit">
        Ingresar
      </v-btn>

    </v-form>
      <v-btn block large depressed class="mt-4" :to="{ name: 'Recovery' }">
        Recuperar mi contraseña
      </v-btn>
    
  </div>
</template>

<script>
import { auth } from "@/firebase";
import {
  signInWithEmailAndPassword,
  setPersistence,
  browserSessionPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import Rules from "@/mixins/rules";

export default {
  data: () => ({
    email: "",
    password: "",
    checkbox: false,
    loading: false,
  }),

  mixins: [Rules],

  methods: {
    async onSubmit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const persistence = this.checkbox
          ? browserLocalPersistence
          : browserSessionPersistence;
        await setPersistence(auth, persistence)
          .then(() => {
            return signInWithEmailAndPassword(auth, this.email, this.password);
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.debug(errorMessage, errorCode);
          });
        this.loading = false;
      }
    },
  },
};
</script>