let unsubscribe;
import _ from 'lodash';
import moment from 'moment'
import { db } from '@/firebase';
import {
  collection,
  query,
  onSnapshot,
  doc,
  where,
  updateDoc,
  getDocs,
  getDoc
} from "firebase/firestore";

export default {
  namespaced: true,
  state: {
    attendees: []
  },
  mutations: {
    setAttendees(state, payload) {
      state.attendees = payload
    }
  },
  actions: {

    // Get Attendees
    async getAttendees({ rootState, commit }, eventId) {

      let q = null;
      const seller = rootState.auth.user.uid;
      const company = rootState.auth.user.companyId;
      const reference = `companies/${company}/junction`


      if (rootState.auth.user.role === 'seller') {
        q = query(collection(db, reference), where("eventId", "==", eventId), where("sellerId", "==", seller));
      } else {
        q = query(collection(db, reference), where("eventId", "==", eventId));

      }

      return new Promise((resolve) => {
        unsubscribe = onSnapshot(q, (querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            items.push(doc.data());
          });
          commit("setAttendees", items);
          resolve();
        });
      })
    },


    // Unsubscribe
    setUnsubscribe() {
      unsubscribe()
    },

    // Find if attendee exists in event
    async findAttendeeInEvent({ rootState }, ticketId) {

      const company_id = rootState.auth.user.companyId;
      const junctionRef = doc(db, `companies/${company_id}/junction`, ticketId);
      const docSnap = await getDoc(junctionRef);

      if (docSnap.exists()) {
        return docSnap.data()
      } else {
        return;
      }

    },


    // -------- OLD


    // Special: [get-specials]
    async getSpecials({ rootState }) {

      const items = []
      const company_id = rootState.auth.user.companyId
      const attendees_ref = collection(db, `companies/${company_id}/attendees`)

      const q = query(attendees_ref, where("level", "==", 2));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        items.push(doc.data())
      });

      return items
    },

    // Special: [fetch-by-rut]
    async fetchByRut({ rootState }, payload) {

      var i = []
      const c = rootState.auth.user.companyId;
      const q = query(collection(db, `companies/${c}/attendees`), where("rut", "==", payload));

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        i.push(doc.data())
      });

      return i[0]
    },

    // Scan : Attendees
    readerAttendeesRT({ rootState, commit }, eventId) {

      var q;
      const u = rootState.auth.user;
      const e = `/companies/${u.companyId}/events/${eventId}/attendees`;

      if (u.role === 'admin' || u.role === 'owner' || u.role === 'scan') {
        q = query(collection(db, e));
      } else {
        q = query(collection(db, e,), where("sellerId", "==", u.uid));
      }

      return new Promise(resolve => {
        unsubscribe = onSnapshot(q, (querySnapshot) => {
          const attendees = [];
          querySnapshot.forEach((doc) => {
            attendees.push(doc.data());
          });
          commit("set_attendees", attendees);
          resolve();
        });
      })
    },

    async updateCheckIn({ rootState }, payload) {

      const eventRef = `companies/${rootState.auth.user.companyId}/events/${payload.event}/attendees`;
      const reference = doc(db, eventRef, payload.rut);

      await updateDoc(reference, {
        checkIn: {
          state: payload.action,
          date: moment().format('YYYY-MM-DD, H:mm:ss'),
          by: rootState.auth.user.displayName
        },
        activeTicket: false,
      });
    },

    async updateState({ rootState }, payload) {
      const eventRef = `companies/${rootState.auth.user.companyId}/events/${payload.event}/attendees`;
      const reference = doc(db, eventRef, payload.rut);

      if (!payload.activeTicket) {
        await updateDoc(reference, {
          checkIn: null,
          activeTicket: true,
        });
      } else {
        await updateDoc(reference, {
          activeTicket: false,
        });
      }
    },




    // Update
    async updateDisabledAttendee({ rootState }, payload) {

      const c = rootState.auth.user.companyId;
      const reference = doc(db, `companies/${c}/attendees`, payload[0]);

      await updateDoc(reference, {
        disabled: payload[1],
      });
    },

    // Find
    async findDisabledAttendee({ rootState }, payload) {

      var i = []
      const c = rootState.auth.user.companyId;
      const q = query(collection(db, `companies/${c}/attendees`), where("rut", "==", payload));

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        i.push(doc.data())
      });

      return i[0]
    },


    // 02. FETCH ATTENDEES
    async fetchAttendees({ rootState, commit }, payload) {

      let q = null;
      const items = [];
      const user_id = rootState.auth.user.uid;
      const role_name = rootState.auth.user.role;
      const company_id = rootState.auth.user.companyId;

      if (role_name === "owner") {
        q = query(collection(db, `companies/${company_id}/junction`), where("eventId", "==", payload));
      } else {
        q = query(collection(db, `companies/${company_id}/junction`), where("eventId", "==", payload), where("sellerId", "==", user_id));
      }

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        items.push(doc.data())
      });

      commit("setAttendees", items)
    },


    // 01 SCAN FIND ATTENDEE
    async scanFindAttendee({ rootState }, payload) {

      let attendee = null;
      const company_id = rootState.auth.user.companyId;

      // references
      const eventRef = `companies/${company_id}/junction`;
      const attendeeRef = `companies/${company_id}/attendees`;

      // queries
      const qAttendee = query(collection(db, attendeeRef), where("rut", "==", payload.id));
      const qEvents = query(collection(db, eventRef), where("eventId", "==", payload.event), where("rut", "==", payload.id));

      // actions
      const snapAttendee = await getDocs(qAttendee);
      const snapEvent = await getDocs(qEvents);

      // process
      if (snapAttendee.size > 0) {
        attendee = _.merge(snapAttendee.docs[0].data(), { enable: true })

        // disabled
        if (attendee.disabled) {
          attendee = { enable: false, motive: "Asistente Bloqueado" }
          return attendee;
        }

        // premium
        if (attendee.level > 1) return attendee;

        // event
        if (snapEvent.size > 0) {
          let item = _.pick(snapEvent.docs[0].data(), ["sellerName", "activeTicket", "ticket_time", "list", "deadLine"])

          // ticket
          if (item.activeTicket) {
            item.enable = true
          } else {
            item.enable = false
            item.motive = "Ticket no habilitado"
          }

          return _.merge(attendee, item);
        } else {
          attendee = { enable: false, motive: "Asistente no está en lista" }
        }

      } else {
        attendee = { enable: false, motive: "Asistente no existe" }
      }

      return attendee;
    },

    // 02. SCAN UPDATE TICKET
    async scanUpdateTicket({ rootState }, payload) {

      const company_id = rootState.auth.user.companyId;
      const attendee_id = `${payload.rut}_${payload.event_id}`

      // references
      const eventRef = `companies/${company_id}/junction`;
      const reference = doc(db, eventRef, attendee_id);

      await updateDoc(reference, {
        ticket_time: moment().format('YYYY-MM-DD, H:mm:ss'),
        ticket_entered: payload.entered,
        activeTicket: false,
      });
    },


    // * Scan: reader scan attendes
    async scannedAttendees({ rootState, commit }, eventId) {

      const companyId = rootState.auth.user.companyId;
      const junctionRef = collection(db, `/companies/${companyId}/junction`);

      const q = query(junctionRef, where("eventId", "==", eventId));

      return new Promise((resolve) => {

        unsubscribe = onSnapshot(q, (querySnapshot) => {
          const items = [];
          querySnapshot.forEach((doc) => {
            items.push({ ticketId: doc.id, ...doc.data() });
          });
          commit("setAttendees", items);
          resolve()
        });

      })
    },


  },
  getters: {
    // Get By State
    getByState: (state) => (payload) => {
      return state.attendees.filter(todo => todo.ticket_entered === payload).length
    },

    // Get By List
    getByList: (state) => (payload) => {
      return state.attendees.filter(todo => todo.list === payload).length
    },

    // * Scan: order by ticket_entered
    getByAccess: (state) => (payload) => {
      return state.attendees.filter(todo => todo.ticket_entered === payload)
    },

  }
}