<template>
  <v-form ref="form" @submit.prevent="onSubmit">
    <!-- [list] -->
    <div class="form-group | mb-4" v-if="user.role === 'owner'">
      <label>Listas <span>*</span></label>
      <v-select :items="lists" outlined item-text="name" dense v-model="list" hide-details :rules="[rules.required]" />
    </div>

    <!-- [rut] -->
    <div class="form-group | mb-4">
      <label>Rut Asistente <span>*</span></label>
      <v-text-field v-model="rut" outlined hide-details dense required :rules="[rules.required]"
        :append-icon="rutValid" />
    </div>

    <!-- [name] -->
    <div class="form-group | mb-6">
      <label>Nombre <span>*</span></label>
      <v-text-field v-model="name" outlined dense hide-details :rules="[rules.required]"
        append-icon="mdi-account-outline" />
    </div>

    <!-- [button] -->
    <v-btn :loading="loading" :disabled="loading" color="primary" type="submit" block depressed large>
      Crear Asistente
    </v-btn>
  </v-form>
</template>

<script>
import _ from "lodash";
import Rut from "rutjs";
import { mapState, mapActions } from "vuex";
import { rulesForm } from "@/assets/settings.js";

import { createTicket } from '@/api/tickets.js';

export default {
  props: ["reset", "event"],

  data: () => ({
    list: "General",
    rut: "",
    name: "",
    rutValid: "mdi-close-circle-outline",
    rules: rulesForm[0],
    loading: false,
  }),

  watch: {
    reset() {
      this.onReset();
    },

    rut(v) {
      var rut = new Rut(String(v));
      this.rut = rut.getNiceRut(false);
      this.rutValid = `mdi-${rut.isValid ? "check-circle-outline" : "close-circle-outline"
        }`;
    },
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("lists", ["lists"]),
  },

  methods: {
    ...mapActions("attendees", ["findAttendeeInEvent"]),

    async saveAttendee() {
      let deadLine = _.find(this.lists, { name: this.list }).date;

      let item = [
        {
          rut: this.rut,
          list: this.list,
          name: this.name,
          disabled: false,
          deadLine: deadLine ? deadLine : null, // ? Fixed
          eventId: this.event.uid,
          sellerId: this.user.uid,
          sellerName: this.user.displayName,
          activeTicket: true,
        },
      ];

      const eventId = this.$route.params.uid;

      await createTicket(eventId, item);

    },

    async onSubmit() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        // 00. Variables
        let ticketId = `${this.rut}_${this.event.uid}`;

        // 01. Find if attendee exist in event
        let resp = await this.findAttendeeInEvent(ticketId);

        // 02. Verify
        if (resp) {
          alert("Asistente ya existe.");
        } else {
          await this.saveAttendee();
        }

        this.loading = false;
        this.onReset();
      }
    },

    onReset() {
      this.$emit("close");
      this.rut = "";
      this.name = "";
      this.list = "General";
      this.$refs.form.resetValidation();
    },
  },
};
</script>