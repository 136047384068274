<template>
  <div class="access">
    <!-- app: header -->

    <!-- components: scan -->
    <div class="pa-4">
      <component-reader @result="onResult" />
    </div>

    <!-- content -->
    <div class="access-content">
      <!-- false -->
      <div v-if="getByAccess(false).length > 0" class="mb-4">
        <v-divider />
        <v-card flat tile>
          <v-card-title class="text-button py-1">
            <span class="font-weight-bold">Asistentes Rechazados</span>
            <v-spacer />
            <v-chip small>{{ getByAccess(false).length }}</v-chip>
          </v-card-title>
          <v-divider />
          <v-list>
            <template v-for="(item, index) in getByAccess(false)">
              <v-list-item :key="item.rut">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-medium text-h6">
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action> {{ item.rut }} </v-list-item-action>
              </v-list-item>
              <v-divider v-if="index < getByAccess(false).length - 1" :key="index" />
            </template>
          </v-list>
        </v-card>
      </div>

      <!-- true -->
      <div v-if="getByAccess(true).length > 0">
        <v-divider />
        <v-card flat tile>
          <v-card-title class="text-button py-1">
            <span class="font-weight-bold">Asistentes Ingresados</span>
            <v-spacer />
            <v-chip small>{{ getByAccess(true).length }}</v-chip>
          </v-card-title>
          <v-divider />
          <v-list>
            <template v-for="(item, index) in getByAccess(true)">
              <v-list-item :key="item.rut">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-medium text-h6">
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action> {{ item.rut }} </v-list-item-action>
              </v-list-item>
              <v-divider v-if="index < getByAccess(false).length - 1" :key="index" />
            </template>
          </v-list>
        </v-card>
      </div>
    </div>

    <!-- dialog: validator -->
    <ScanValid v-model="dialog" :data="data" @input="closeDialog" />
  </div>
</template>

<script>
// components
import ComponentReader from "./components/Reader.vue";
import { mapActions, mapGetters } from "vuex";

import ScanValid from "@/views/scan/ScanValid.vue";

export default {
  components: { ComponentReader, ScanValid },

  data: () => ({
    item: {},
    dialog: false,
    eventId: null,
    loading: false,
    // 
    data: {}
  }),

  computed: {
    ...mapGetters("attendees", ["getByAccess"]),
  },

  methods: {
    ...mapActions("attendees", ["scannedAttendees"]),

    closeDialog() {
      this.data = {};
    },



    onResult(e) {
      this.data = e;
      this.dialog = true;
    },

    onResp() {
      this.item = {};
      this.dialog = false;
    },
  },

  created() {
    this.eventId = this.$route.params.uid;
  },

  async mounted() {
    this.loading = true;
    await this.scannedAttendees(this.eventId);
    this.loading = false;
  },

  beforeDestroy() {
    this.$store.dispatch("attendees/setUnsubscribe");
  },
};
</script>