<template>
    <div fluid>
        <!-- mobile -->
        <div class="layout-mobile" v-if="!desktop">

            <!-- tab -->
            <v-tabs fixed-tabs v-model="tab" background-color="grey lighten-2" height="56">
                <v-tab>Asistentes</v-tab>
                <v-tab v-if="user.owner">Listas</v-tab>
                <v-tab v-if="user.owner">Indicadores</v-tab>
            </v-tabs>

            <!-- content -->
            <v-tabs-items v-model="tab" class="transparent pa-4">

                <!-- attendees -->
                <v-tab-item>
                    <v-btn block large depressed color="primary" class="mb-4" @click="dialog = true">
                        Agregar Asistente
                    </v-btn>
                    <event-attendees :items="attendees" :enable="enable" :search="search" />
                </v-tab-item>

                <!-- lists -->
                <v-tab-item>
                    <module-list-view :eventId="data.uid" class="mb-3" />
                    <v-btn large @click="drawer = true" block color="primary">
                        <v-icon small class="mr-1">mdi-plus</v-icon>
                        Crear Lista
                    </v-btn>
                </v-tab-item>

                <!-- dashboard -->
                <v-tab-item>
                    <!-- kpis -->
                    <v-card outlined class="mb-4">
                        <v-card-title>
                            <span class="mdi mdi-chart-line mr-2 primary--text"></span>
                            Indicadores
                            <v-spacer />
                            <v-chip label color="grey lighten-3">
                                <span class="font-weight-medium text-body-1">Total: {{ attendees.length }}</span>
                            </v-chip>
                        </v-card-title>
                        <v-card-text>
                            <v-list-item class="px-0">
                                <v-list-item-content>
                                    <v-list-item-subtitle class="text-subtitle-1 font-weight-medium mb-1">
                                        Aceptados {{ getByState(true) }}
                                    </v-list-item-subtitle>
                                    <v-progress-linear :value="(getByState(true) * 100) / attendees.length"
                                        color="success" />
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item class="px-0">
                                <v-list-item-content>
                                    <v-list-item-subtitle class="text-subtitle-1 font-weight-medium mb-1">
                                        Rechazados {{ getByState(false) }}
                                    </v-list-item-subtitle>
                                    <v-progress-linear :value="(getByState(true) * 100) / attendees.length"
                                        color="error" />
                                </v-list-item-content>
                            </v-list-item>
                        </v-card-text>
                    </v-card>

                    <component-sellers :data="attendees" />
                </v-tab-item>
            </v-tabs-items>
        </div>

        <!-- desktop -->
        <v-container fluid class="px-10" v-else>
            <!-- [header] -->
            <v-row align="center" v-if="desktop">
                <v-col>
                    <v-list-item two-line class="px-0">
                        <v-list-item-content>
                            <v-list-item-subtitle>
                                Home / Eventos / {{ data.name }}
                            </v-list-item-subtitle>
                            <v-list-item-title class="text-h5 | font-weight-medium">
                                {{ data.name }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col class="text-end">
                    <v-btn v-if="enable" @click="dialog = true" color="primary">Nuevo Asistente
                    </v-btn>
                </v-col>
            </v-row>

            <!-- content -->
            <v-row>
                <v-col cols="12" class="py-5" v-if="this.$vuetify.breakpoint.mobile">
                    <v-btn block large depressed color="primary" @click="dialog = true">
                        Agregar Asistente
                    </v-btn>
                </v-col>

                <!-- sidebar -->
                <v-col cols="12" md="4" lg="3" v-if="user.role === 'owner'">
                    <!-- list -->
                    <v-card outlined>
                        <v-card-title>Listas</v-card-title>
                        <v-card-text>
                            <module-list-view :eventId="data.uid" class="mb-3" />
                            <v-btn large depressed @click="drawer = true" block color="primary">
                                <v-icon small class="mr-1">mdi-plus</v-icon>
                                Crear Lista
                            </v-btn>
                        </v-card-text>
                    </v-card>

                    <!-- kpi -->
                    <v-card outlined class="mt-4">
                        <v-card-title>
                            <span class="mdi mdi-chart-line mr-2 primary--text"></span>
                            Indicadores
                            <v-spacer />
                            <v-chip label >
                                <span class="font-weight-medium text-body-1">Total: {{ attendees.length }}</span>
                            </v-chip>
                        </v-card-title>
                        <v-card-text>
                            <v-list-item class="px-0">
                                <v-list-item-content>
                                    <v-list-item-subtitle class="text-subtitle-1 font-weight-medium mb-1">
                                        Aceptados {{ getByState(true) }}
                                    </v-list-item-subtitle>
                                    <v-progress-linear :value="(getByState(true) * 100) / attendees.length"
                                        color="success" />
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item class="px-0">
                                <v-list-item-content>
                                    <v-list-item-subtitle class="text-subtitle-1 font-weight-medium mb-1">
                                        Rechazados {{ getByState(false) }}
                                    </v-list-item-subtitle>
                                    <v-progress-linear :value="(getByState(true) * 100) / attendees.length"
                                        color="error" />
                                </v-list-item-content>
                            </v-list-item>
                        </v-card-text>

                        <v-divider></v-divider>
                        <v-card-title>
                            <span class="mdi mdi-account-star-outline primary--text mr-3"></span>
                            Top Embajadores
                        </v-card-title>
                        <component-sellers-top :data="attendees" />
                    </v-card>
                </v-col>

                <!-- attendees -->
                <v-col>
                    <v-card outlined>

                        <!-- tabs -->
                        <v-tabs v-model="tab" height="56">
                            <v-tab>Asistentes</v-tab>
                            <v-tab>Embajadores</v-tab>
                        </v-tabs>

                        <!-- table -->
                        <v-tabs-items v-model="tab" style="min-height: 75vh">
                            <!-- attendees -->
                            <v-tab-item>
                                <event-attendees :items="attendees" :enable="enable" :search="search" />
                            </v-tab-item>

                            <!-- dashboard -->
                            <v-tab-item>
                                <component-sellers :data="attendees" />
                            </v-tab-item>
                        </v-tabs-items>

                    </v-card>
                </v-col>
            </v-row>

        </v-container>


        <!-- [dialog: create] -->
        <event-add-attendees v-model="dialog" @close="dialog = false" :event="data" />

        <!-- draw: create-list -->
        <module-list-create v-model="drawer" :event="data" />
    </div>
</template>

<script>
// 2025
import moment from "moment";
import { mapActions, mapGetters, mapState } from "vuex";

import EventAttendees from "../../components/event/Attendees.vue";
import EventAddAttendees from "../../components/event/CreateAttendee.vue";

// Modules
import ModuleListView from "../../modules/lists/List.vue";
import ModuleListCreate from "../../modules/lists/Create.vue";

// 2023

import ComponentSellers from '../../modules/event/components/Sellers.vue'
import ComponentSellersTop from '../../modules/event/components/SellerTop.vue'

export default {
    components: {
        EventAttendees,
        EventAddAttendees,
        ModuleListView,
        ModuleListCreate,
        ComponentSellers,
        ComponentSellersTop
    },

    data: () => ({
        data: {},
        drawer: false,
        loading: false,
        enable: false,
        dialog: false,
        search: "",
        //
        tab: null,
    }),

    computed: {
        ...mapState(["desktop"]),
        ...mapState("auth", ["user"]),
        ...mapState("attendees", ["attendees"]),

        ...mapGetters("attendees", ["getByState"]),
    },

    methods: {
        ...mapActions("lists", ["reader"]),
        ...mapActions("events", ["getEvent"]),
        ...mapActions("attendees", ["getAttendees"]),

        async getEventInformation() {
            await this.getEvent(this.$route.params.uid).then((resp) => {
                this.data = resp;
            });
        },

        getEnable() {
            var today = new Date();
            var finish = this.data.finishDate.toDate();

            this.enable = moment(finish, "DD-MM-YYYY H:mm").isAfter(
                today,
                "DD-MM-YYYY H:mm"
            );
        },
    },

    async created() {
        this.loading = true;

        // 01. Get Event Information
        await this.getEventInformation();
        await this.reader(this.$route.params.uid);

        // 02. Get Attendees
        await this.getAttendees(this.$route.params.uid);

        // 03. Set Page
        this.getEnable();
        this.$root.$emit("app-bar", this.data.name, 1);

        this.loading = false;
    },

    beforeDestroy() {
        this.$store.commit("attendees/setAttendees", []);
        this.$store.dispatch("attendees/setUnsubscribe");
    },
};
</script>