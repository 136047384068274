<template>
  <v-container fluid>

    <v-row align="center" v-if="!$vuetify.breakpoint.mobile">
      <v-col>
        <v-list-item two-line class="px-0">
          <v-list-item-content>
            <v-list-item-subtitle> Home / Staff </v-list-item-subtitle>
            <v-list-item-title class="text-h5 | font-weight-medium">
              Staff
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col class="text-end">
        <v-btn @click="dialog = true" color="primary">Crear Usuario</v-btn>
      </v-col>
    </v-row>

    <!-- 03. Dialog -->
    <v-dialog v-model="dialog" persistent max-width="400">
      <v-card>
        <!-- Title -->
        <v-card-title class="d-block mb-4" style="line-height: 0.2">
          <p class="text-overline text--secondary | mb-0">Mis Usuarios</p>
          <span>Crear Usuario</span>
        </v-card-title>
        <!-- Content -->
        <v-card-text>
          <v-form ref="form" @submit.prevent="onCreate">
            <!-- 02. Name -->
            <v-text-field label="Nombre" v-model="newUser.name" class="mt-6" append-icon="mdi-account-outline"
              :rules="[rules.required]" required />
            <!-- 03. Email -->
            <v-text-field label="Email" v-model="newUser.email" append-icon="mdi-email-outline"
              :rules="[rules.required, rules.email]" required />
            <!-- 04. Password -->
            <v-text-field label="Password" v-model="newUser.password" append-icon="mdi-key-outline"
              :rules="[rules.required, rules.count]" required />
            <!-- 05. Rol -->
            <v-select label="Rol" v-model="newUser.role" :items="roles" item-text="text" item-value="value"
              return-object :rules="[rules.required]" required />
            <!-- Actions -->
            <div>
              <!-- 06. Create -->
              <v-btn color="primary" :loading="loading" :disabled="loading" type="submit" block depressed>
                Crear Usuario
              </v-btn>
              <!-- 07. Reset -->
              <v-btn @click="onReset" class="mt-3" :disabled="loading" block depressed>Cancelar</v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- v2.4.6 -->

    <v-container v-if="!$vuetify.breakpoint.mobile" fluid>
      <v-card>
        <v-data-table v-model="selected" :headers="headers" :items="myUsers" item-key="uid">
          <!-- disables -->
          <template v-slot:[`item.disabled`]="{ item }">
            <v-chip class="font-weight-bold" :class="!item.disabled ? 'success' : 'error'" label small>
              {{ !item.disabled ? "ACTIVADO" : "DESACTIVADO" }}
            </v-chip>
          </template>

          <!-- Delete -->
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon :ref="item.uid" small @click="deleteItem(item.uid)">
              mdi-delete
            </v-icon>
          </template>
          <!-- // -->
        </v-data-table>
      </v-card>
    </v-container>

    <!-- Mobile -->
    <div v-else>
      <v-btn @click="dialog = true" color="primary" block>Crear Usuario</v-btn>
      <v-list>
        <template v-for="(item, index) in myUsers">
          <v-list-item :key="item.uid">
            <v-list-item-avatar>
              <v-icon :class="item.disabled ? 'grey lighten-1' : 'accent'" size="20" dark>
                {{ item.role === "seller" ? "mdi-account" : "mdi-qrcode-scan" }}
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium">
                {{ item.displayName }}
              </v-list-item-title>
              <v-list-item-subtitle class="text-capitalize">
                {{ item.role }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="item.uid !== user.uid && user.role === 'owner'">
              <v-btn icon>
                <v-icon color="grey lighten-1" @click="deleteItem(item.uid)">
                  mdi-delete
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider v-if="index < myUsers.length - 1" :key="index" />
        </template>
      </v-list>
    </div>


    <!-- notification -->
    <notification :show="noti.show" :msg="noti.msg" :time="noti.time" :type="noti.type" />
  </v-container>
</template>

<script>
import _ from "lodash";
import { functions, db } from "@/firebase";
import { httpsCallable } from "firebase/functions";
import { collection, query, where, getDocs } from "firebase/firestore";
import { headerMyUsers } from "@/assets/settings";
import { mapState } from "vuex";

// v2.4.6
import { rulesUser } from "../../assets/rules";
import Notification from "@/components/ui/Notification.vue";

// 2023

export default {
  components: { Notification },
  // Data
  data() {
    return {
      rules: rulesUser,
      dialog: false,
      myUsers: [],
      newUser: {},
      loading: false,
      roles: [
        { text: "Embajador", value: "seller" },
        { text: "Escaner", value: "scan" },
      ],
      headers: headerMyUsers,
      selected: [],
      singleSelect: false,
      // v2.4.6
      isLoading: false,
      isLoadingMsg: "Cargando Data",
      noti: { show: false },
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  // Methods
  methods: {
    // 01. Create
    async onCreate() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const addMessage = httpsCallable(functions, "userCreate");

        const data = {
          disabled: false,
          email: this.newUser.email,
          displayName: this.newUser.name,
          password: this.newUser.password,
          companyName: this.$store.state.auth.user.companyName,
          customClaims: {
            [this.newUser.role.value]: true,
            companyId: this.$store.state.auth.user.companyId,
            companyName: this.$store.state.auth.user.companyName,
          },
        };

        await addMessage(data)
          .then((result) => {
            console.debug("onCreate: ", result);
            this.fetchData();
          })
          .catch((err) => {
            console.debug("about.vue: ", err);
          });

        this.onReset();
      }
    },

    // 02. Reader
    async fetchData() {
      const uid = this.$store.state.auth.user.companyId;
      const items = [];

      const q = query(collection(db, "users"), where("company.uid", "==", uid));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        items.push(_.merge({ uid: doc.id }, doc.data()));
      });

      this.myUsers = _.reject(items, { uid: this.user.uid });
    },
    // 05. Reset
    onReset() {
      (this.dialog = false), (this.loading = false), this.$refs.form.reset();
    },

    // v2.4.6
    async deleteItem(id) {
      if (confirm("Seguro que desea eliminar")) {
        this.isLoading = true;
        this.isLoadingMsg = "Eliminando Usuario";
        const addMessage = httpsCallable(functions, "deleteUser");

        await addMessage(id)
          .then((result) => {
            this.noti = {
              show: true,
              time: "1500",
              type: "success",
              msg: "Usuario eliminado correctamente",
            };
            this.fetchData(result);
          })
          .catch((err) => {
            console.debug("about.vue: ", err);
          });

        this.isLoading = false;

        setTimeout(() => {
          this.noti.show = false;
        }, 1500);
      }
    },

    actionDrawer() {
      this.$root.$emit("actionDrawers");
    },
  },

  async created() {
    this.isLoading = true;
    await this.fetchData();
    this.isLoading = false;
  },
};
</script>