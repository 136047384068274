import store from "@/store";
import { db } from '@/firebase';
import { doc, getDoc, updateDoc, writeBatch, increment, runTransaction } from "firebase/firestore";

const companyId = store.state.auth.user.companyId;

export const createTicket = async (eventId, tickets) => {
    const batch = writeBatch(db);
    const eventPath = `companies/${companyId}/events`;
    const ticketPath = `companies/${companyId}/junction`;

    try {
        tickets.forEach(ticket => {
            const newTicketRef = doc(db, ticketPath, `${ticket.rut}_${eventId}`);
            batch.set(newTicketRef, { createdAt: new Date(), ...ticket });
        });

        const eventRef = doc(db, eventPath, eventId);
        batch.update(eventRef, { "ticketCount": increment(tickets.length) });

        await batch.commit();
        console.log(`Successfully created ${tickets.length} ticket(s)`);
    } catch (error) {
        console.error('Error in createTicket:', error);
        throw error;
    }
}

export const deleteTicket = async (eventId, ticketId) => {
    const ticketPath = `companies/${companyId}/junction`;
    const eventPath = `companies/${companyId}/events`;

    try {
        await runTransaction(db, async (transaction) => {
            const ticketRef = doc(db, ticketPath, `${ticketId}_${eventId}`);
            const ticketDoc = await transaction.get(ticketRef);

            if (!ticketDoc.exists()) { throw new Error('Ticket does not exist') }

            const eventRef = doc(db, eventPath, eventId);
            const eventDoc = await transaction.get(eventRef);

            transaction.delete(ticketRef);
            if (eventDoc.exists()) { transaction.update(eventRef, { "ticketCount": increment(-1) }); }
        });

        console.log(`Successfully deleted ticket ${ticketId} for event ${eventId}`);
        return true;
    } catch (error) {
        console.error('Error deleting ticket:', error);
        throw error;
    }
}

export const fetchTickets = async (eventId, ticketId) => {
    const userPath = `companies/${companyId}/attendees`;
    const ticketPath = `companies/${companyId}/junction`;

    try {
        const userDocRef = doc(db, userPath, ticketId);
        const ticketDocRef = doc(db, ticketPath, `${ticketId}_${eventId}`);

        const [userDocSnap, ticketDocSnap] = await Promise.all([
            getDoc(userDocRef),
            getDoc(ticketDocRef)
        ]);

        if (userDocSnap.exists()) {
            const userData = userDocSnap.data();

            if (userData?.disabled === true) {
                return { icon: 'mdi-close', color: 'error', status: 'Asistente Bloqueado', data: userData };
            } else if (userData?.vip === true) {
                return { icon: 'mdi-check', color: 'amber', status: 'Asistente Especial', data: userData };
            }
        }

        if (ticketDocSnap.exists()) {
            const ticketData = ticketDocSnap.data();

            if (ticketData?.disabled !== true) {
                return { icon: 'mdi-check', color: 'success', status: 'Ticket Valido', data: ticketData };
            } else {
                return { icon: 'mdi-close', color: 'error', status: 'Ticket No Valido', message: 'Ticket ya utilizado' };
            }
        }

        return { icon: 'mdi-close', color: 'error', status: 'No Encontrado', message: 'Ticket no econtrado' };
    } catch (error) {
        console.error('Error al buscar el ticket:', error);
        return { icon: 'mdi-alert', color: 'warning', status: 'Error', message: 'Error al procesar la solicitud' };
    }
}

export const validTickets = async (eventId, ticketId, status) => {
    const ticketPath = `companies/${companyId}/junction`;
    const eventPath = `companies/${companyId}/events`;
    const batch = writeBatch(db);

    try {
        const ticketRef = doc(db, ticketPath, `${ticketId}_${eventId}`);
        const eventRef = doc(db, eventPath, eventId);

        await updateDoc(ticketRef, {
            disabled: true,
            ticket_entered: status,
            validatedAt: new Date()
        })

        const counterField = status ? 'entered' : 'rejected';
        batch.update(eventRef, { [counterField]: increment(1) });

        await batch.commit();

        console.log('Ticket validado y contador actualizado con éxito')
    } catch (error) {
        console.error("error tickets.js validTickets", error)
        throw new Error();
    }
}