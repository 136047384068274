<template>
  <v-dialog :value="value" hide-overlay persistent :fullscreen="$vuetify.breakpoint.mobile" max-width="600"
    transition="slide-x-reverse-transition">
    <v-card tile>
      <v-card-title>Crear Nuevo Evento</v-card-title>
      <!-- [form] -->
      <v-form :disabled="loading" ref="form" @submit.prevent="onSubmit">
        <v-card-text class="px-6">
          <v-row>
            <!-- [name] -->
            <v-col cols="12" md="6">
              <div class="form-group">
                <label>Nombre Evento</label>
                <v-text-field flat solo-inverted hide-details v-model="name" :rules="[rules.required]" />
              </div>
            </v-col>

            <!-- [type] -->
            <v-col cols="12" md="6">
            </v-col>

            <!-- [start-date] -->
            <v-col cols="6" md="6">
              <div class="form-group">
                <label>Fecha Inicio</label>
                <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="0" transition="scale-transition"
                  offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="start_date" flat solo-inverted readonly hide-details v-bind="attrs" v-on="on"
                      :rules="[rules.required]"></v-text-field>
                  </template>
                  <v-date-picker no-title :min="currentDate" v-model="start_date" @input="menu = false" />
                </v-menu>
              </div>
            </v-col>

            <!-- [hour-start] -->
            <v-col cols="6" md="6">
              <div class="form-group">
                <label>Hora Inicio</label>
                <v-text-field type="time" solo-inverted flat hide-details v-model="start_time"
                  :rules="[rules.required]" />
              </div>
            </v-col>

            <!-- [finish-date] -->
            <v-col cols="6" md="6">
              <div class="form-group">
                <label>Fecha Termino</label>
                <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="0" transition="scale-transition"
                  offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="end_date" solo-inverted flat readonly hide-details v-bind="attrs" v-on="on"
                      :rules="[rules.required]"></v-text-field>
                  </template>
                  <v-date-picker no-title :min="currentDate" v-model="end_date" @input="menu2 = false" />
                </v-menu>
              </div>
            </v-col>

            <!-- [hour-finish] -->
            <v-col cols="6" md="6">
              <div class="form-group">
                <label>Hora Termino</label>
                <v-text-field type="time" solo-inverted flat hide-details v-model="end_time"
                  :rules="[rules.required]" />
              </div>
            </v-col>

            <!-- buttons -->
            <v-col cols="12" class="text-end pb-5">
              <v-btn :disabled="loading" @click="close" large depressed class="mr-4">
                Cancelar
              </v-btn>
              <v-btn :disabled="loading" :loading="loading" type="submit" large color="primary" depressed>
                Crear Evento
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import { rulesUser } from "@/assets/rules.js";

import { createNewEvent } from "@/api/events.js";

export default {
  props: ["value"],

  data: () => ({
    menu: null,
    menu2: null,
    rules: rulesUser,
    loading: false,
    eventOptions: [
      { id: 1, name: "Evento Normal" },
      { id: 2, name: "Cumpleaños" },
      { id: 3, name: "Evento Especial" },
      { id: 4, name: "Evento Privado" },
    ],
    // event
    name: null,
    type: { id: 1, name: "Evento Normal" },
    end_date: null,
    end_time: null,
    start_date: null,
    start_time: null,
    // * New
    currentDate: "",
  }),


  methods: {

    async onSubmit() {
      this.loading = true;

      if (this.$refs.form.validate()) {
        var start = new Date(
          String(this.start_date + " " + this.start_time).replace(/\s/, "T")
        );
        var end = new Date(
          String(this.end_date + " " + this.end_time).replace(/\s/, "T")
        );

        var new_event = {
          name: this.name,
          disabled: false,
          startDate: start,
          finishDate: end,
        };

        await createNewEvent(new_event);
        this.close();
      }

      this.loading = false;
    },

    close() {
      this.event = {};
      this.$emit("close");
      this.$refs.form.reset();
    },
  },

  mounted() {
    this.currentDate = moment(new Date()).format("YYYY-MM-DD");
  },
};
</script>