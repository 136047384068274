<template>
    <v-dialog v-model="updateExists" max-width="300px">
        <v-card max-width="300">
            <v-card color="#1A237E" flat>
                <v-card-text class="pa-12">
                    <v-img src="@/assets/relevent.png" />
                </v-card-text>
            </v-card>
            <v-card-text class="text-center">
                <h1 class="text-h6 | px-9 " style="line-height: 1.2">
                    ¡Actualización disponible!
                </h1>
                <p class="mt-6 mb-6">
                    Hay una nueva actualización disponible para Aldus con nuevas
                    característica.
                </p>
                <v-btn color="primary" @click="refreshApp" large block depressed>
                    Actualiza Aquí
                </v-btn>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import update from "@/mixins/update";

export default {
    mixins: [update]
};
</script>

<style scoped></style>