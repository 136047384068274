<template>
  <div>
    <v-container class="px-md-6">
      <h1 class="text-h4 font-weight-semibold mb-4">
        {{ `Pagos ${currentYear}` }}
      </h1>
      <v-card flat>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Mes</th>
                <th class="text-end">Estado</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item.month">
                <td class="text-subtitle-2 font-weight-semibold text-capitalize">
                  {{ item.month }}
                </td>
                <td class="text-end">
                  <v-chip small class="font-weight-semibold" v-if="item.state" color="green"
                    text-color="white">Pagado</v-chip>
                  <v-chip small class="font-weight-semibold" v-else color="red" text-color="white">Pendiente</v-chip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";

// import
import moment from "moment";
import { mapState } from "vuex";
// components

export default {

  data: () => ({
    items: [],
    loading: false,
    currentYear: null,
  }),

  computed: {
    ...mapState("auth", ["user"]),
  },

  methods: {
    async onSubmit() {
      const months = [
        { enero: false },
        { febrero: false },
        { marzo: false },
        { abril: false },
        { mayo: false },
        { junio: false },
        { julio: false },
        { agosto: false },
        { septiembre: false },
        { octubre: false },
        { noviembre: false },
        { diciembre: false },
      ];
      const docRef = doc(
        db,
        "companies",
        this.user.companyId,
        "subscriptions",
        this.currentYear
      );
      await setDoc(docRef, { months: months });
    },

    async fetchSubscription(companyId, currentYear) {
      const companyDocRef = doc(db, "companies", companyId);
      const subscriptionDocRef = doc(
        companyDocRef,
        "subscriptions",
        currentYear
      );
      const docSnap = await getDoc(subscriptionDocRef);

      if (docSnap.exists()) {
        // month
        const months = docSnap.data().months.map((obj) => {
          const [month, state] = Object.entries(obj)[0];
          return { month: month, state: state };
        });

        const currentMonthIndex = new Date().getMonth();
        const monthKeys = Object.keys(months);

        const filteredMonths = {};
        for (let i = 0; i <= currentMonthIndex; i++) {
          const month = monthKeys[i];
          filteredMonths[month] = months[month];
        }

        this.items = filteredMonths;
      }
    },
  },

  async created() {
    // date
    this.currentYear = moment().format("YYYY");
    const companyId = this.user.companyId;

    this.loading = true;
    await this.fetchSubscription(companyId, this.currentYear);
    this.loading = false;
  },
};
</script>
