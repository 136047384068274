<template>
  <v-dialog persistent :value="value" max-width="340">
    <v-card>
      <v-card-title class="text-h5">
        Bloquear Asistente
      </v-card-title>

      <v-card-text class="pt-3">
        <v-form ref="form">
          <v-text-field v-model="item" label="RUT Asistente" class="mb-5" outlined hide-details />
        </v-form>
      </v-card-text>
      <v-divider />

      <v-card-actions class="px-6 py-4">
        <v-spacer></v-spacer>
        <v-btn @click="onClose" color="grey" outlined depressed>Cancelar</v-btn>
        <v-btn color="primary" @click="onSubmit" :loading="loading" :disabled="disabled" depressed>
          Bloquear
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Rut from "rutjs";
import { addAttendeeBlocked } from "@/api/attendees"

export default {
  props: ["value"],

  data: () => ({
    item: "",
    loading: false,
    disabled: true,
  }),

  watch: {
    value(params) {
      if (params) return;
      this.item = "";
    },

    item(parmas) {
      var rut = new Rut(String(parmas));
      this.item = rut.getNiceRut(false);
      this.disabled = rut.isValid ? false : true;
    }
  },

  methods: {
    onClose() {
      this.$emit("input", false)
    },

    async onSubmit() {
      try {
        this.loading = true;
        await addAttendeeBlocked(this.item);
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false;
        this.$emit("result", this.item)
        this.$emit("input", false)
      }

    }
  },
}
</script>