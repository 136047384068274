<template>
    <div>
        <p class="text-caption text-center text--disabled mt-2">Last version: {{ currentVersion }}</p>

        <v-card v-if="isUpdateAvailable" class="mb-4" outlined>
            <v-card-text class="d-flex justify-space-between">
                <span class="font-weight-bold">Existe Actualización:</span>
                <span>{{ latestVersion }}</span>
            </v-card-text>
        </v-card>

        <v-btn @click="handleUpdateClick" color="primary" depressed block>Forzar Actualización</v-btn>
    </div>
</template>

<script>
import { db } from '@/firebase';
import { doc, getDoc } from "firebase/firestore";

export default {

    data: () => ({
        latestVersion: "",
        currentVersion: process.env.VUE_APP_VERSION,
        isUpdateAvailable: false,
    }),

    methods: {
        async checkForUpdate() {
            try {
                const docRef = doc(db, "settings", "version");
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    this.latestVersion = docSnap.data().version;
                    this.isUpdateAvailable = this.latestVersion !== this.currentVersion;
                }
            } catch (error) {
                console.error("Error obteniendo la versión desde Firebase:", error);
            }
        },
        handleUpdateClick() {
            window.location.reload();
        },
    },

    mounted() {
        this.checkForUpdate()
    },
}
</script>