<template>
    <v-dialog :value="value" max-width="340" persistent>
        <v-card>
            <v-card-title class="text-h5">
                Agregar VIP
            </v-card-title>

            <v-card-text class="pt-3">
                <v-form ref="form">
                    <v-text-field v-model="rut" label="RUT Asistente" class="mb-5" outlined hide-details />
                </v-form>
            </v-card-text>

            <v-card-actions class="px-6 py-4">
                <v-spacer></v-spacer>
                <v-btn @click="onClose" color="grey" outlined depressed>Cancelar</v-btn>
                <v-btn color="primary" @click="onSubmit" :loading="loading" :disabled="disabled" depressed>
                    Bloquear
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Rut from "rutjs";
import { addAttendeeVip } from '@/api/attendees.js'

export default {
    props: ["value"],

    data: () => ({
        rut: "",
        loading: false,
        disabled: true
    }),

    watch: {
        rut(parmas) {
            var rut = new Rut(String(parmas));
            this.rut = rut.getNiceRut(false);
            this.disabled = rut.isValid ? false : true;
        }
    },

    methods: {
        onClose() {
            this.$emit("input", false);
            this.rut = "";
        },

        async onSubmit() {
            try {
                this.loading = true;
                await addAttendeeVip(this.rut);
                this.$emit("result", this.rut)
            } catch (error) {
                console.error(error)
            } finally {
                this.loading = false;
                this.onClose();
            }

        }
    }
}
</script>