import { render, staticRenderFns } from "./Reader.vue?vue&type=template&id=711e9911&scoped=true"
import script from "./Reader.vue?vue&type=script&lang=js"
export * from "./Reader.vue?vue&type=script&lang=js"
import style0 from "./Reader.vue?vue&type=style&index=0&id=711e9911&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "711e9911",
  null
  
)

export default component.exports