<template>
    <v-container>
        <div class="text-center pt-6">
            <v-img src="@/assets/img/icon.svg" width="80" class="mx-auto" />
            <div class="px-9"><DrawerUpdate /></div>
        </div>
    </v-container>
</template>

<script>
import DrawerUpdate from '@/layouts/components/DrawerUpdate.vue'

export default {
    components: { DrawerUpdate }
}
</script>