<template>
    <v-app-bar v-if="$vuetify.breakpoint.mobile" style="z-index: 998" app>
        <v-app-bar-nav-icon v-if="backButton" @click="$emit('input')" />
        <v-btn v-else @click="$router.back()" icon><v-icon>mdi-arrow-left</v-icon></v-btn>
        <v-toolbar-title>Relevent</v-toolbar-title>
    </v-app-bar>
</template>

<script>
export default {
    computed: {
        backButton() {
            return this.$route.name !== "MyEvent" && this.$route.name !== "Scan";
        }
    }
}
</script>