<template>
  <v-overlay :value="value" :color="$vuetify.theme.dark ? '#0a0a0a' : '#f8f9fd'" opacity="1" z-index="999">
    <v-progress-circular color="primary" width="2" size="33" indeterminate />
  </v-overlay>
</template>

<script>
export default {
  props: ['value']
}
</script>