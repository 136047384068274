<template>
  <v-container fluid>
    <!-- Header -->


    <!-- [header] -->
    <v-row align="center" v-if="!$vuetify.breakpoint.mobile">
      <v-col>
        <v-list-item two-line class="px-0">
          <v-list-item-content>
            <v-list-item-subtitle> Home / Asistentes </v-list-item-subtitle>
            <v-list-item-title class="text-h5 | font-weight-medium">
              Lista Negra
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col class="text-end">
        <v-btn @click="dialog = true" color="primary">Bloquear Asistente</v-btn>
      </v-col>
    </v-row>

    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr v-for="item, index in items" :key="index">
            <td>{{ item.uid }}</td>
            <td class="text-end"><v-btn :loading="item.loading" @click="onRemove(index, item.uid)" color="red" text
                small>Eliminar</v-btn></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <dialog-searchUser v-model="dialog" @result="onResult" />

    <v-snackbar v-model="snackbar" color="success" absolute right top>
      Usuario <strong>actualizado</strong> correctante
    </v-snackbar>

    <!-- [loading] -->
    <template v-if="loading">
      <v-skeleton-loader v-for="item in 4" :key="item" class="mb-2 mt-2" type="list-item-avatar-two-line" />
    </template>
  </v-container>
</template>

<script>
import DialogSearchUser from "../../components/attendees/AddBlackList.vue";
import { getAllBlockedAttendees, removeAttendeeBlocked } from "@/api/attendees"

export default {
  components: { DialogSearchUser },

  data: () => ({
    items: [],
    dialog: false,
    loading: false,
    snackbar: false,
    isLoading: false,
  }),

  methods: {
    async fetchAttendees() {
      try {
        this.loading = true;
        this.items = await getAllBlockedAttendees();
      } catch (error) {
        console.debug(error)
      } finally {
        this.loading = false;
      }
    },

    onResult(params) {
      this.items.push({ uid: params, loading: false })
    },

    async onRemove(index, uid) {

      try {
        this.items[index].loading = true;
        await removeAttendeeBlocked(uid);
        this.items.splice(index, 1);
      } catch (error) {
        console.error(error)
        this.items[index].loading = false;
      } 
    }

  },

  mounted() {
    this.fetchAttendees()
  }
};
</script>