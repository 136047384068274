export default {
  namespaced: true,
  state: {
    user: {},
    company: {},
  },
  mutations: {
    SET_USER(state, payload) { state.user = payload },
    SET_COMPANY(state, payload) { state.company = payload }
  },
};
