<template>
  <v-navigation-drawer :width="width" :color="color" :value="value" @input="$emit('input', $event)"
    style="z-index: 998;" app >
    <template v-slot:prepend>
      <DrawerLogo class="mt-4 pl-1" @close="$emit('input', $event)" />

      <div class="px-4 pt-6">
        <DrawerScan v-if="user.scan" class="mt-6" />
      </div>
    </template>

    <div class="px-3">
      <DrawerNavigation />
    </div>

    <template v-slot:append>
      <div class="pa-3">
        <v-list dense nav>
          <v-list-item :to="{ name: 'version' }" link>
            <v-list-item-icon>
              <span v-html="iconInfo"></span>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Información</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-card class="mx-2 mb-4" outlined>
          <v-list-item dense class="px-2">
            <v-list-item-content>
              <v-list-item-title>{{ user.displayName }}</v-list-item-title>
              <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn @click="onClose" icon>
                <v-icon color="grey lighten-1">mdi-logout</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-card>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";
import { signOutAction } from '@/api/auth';
import { iconInfo } from '@/mixins/icons'

import DrawerLogo from './DrawerLogo.vue';
import DrawerScan from './DrawerScan.vue';
import DrawerNavigation from './DrawerNavigation.vue';

export default {
  props: ["value"],

  data: () => ({
    iconInfo: iconInfo,
  }),

  components: { DrawerLogo, DrawerScan, DrawerNavigation },

  computed: {
    ...mapState("auth", ["user", "company"]),

    width() {
      return this.$vuetify.breakpoint.mobile ? '100%' : '280';
    },

    color() {
      return this.$vuetify.theme.dark ? '#1b1b1b' : '#f9fafb'
    }
  },

  methods: {
    async onClose() {
      if (confirm("Seguro que quieres salir")) {
        await signOutAction()
      }
    },
  },
};
</script>