<template>
    <v-form class="date-picker rounded" :class="{ isDesktop: !isMobile }" @submit.prevent="onSubmit">
        <v-select v-model="selectedMonth" :items="months" item-text="name" item-value="value" label="Mes" outlined dense
            hide-details />
        <v-select v-model="selectedYear" :items="years" label="Año" outlined dense hide-details />
        <v-btn type="submit" color="primary" outlined depressed block>Filtrar</v-btn>
    </v-form>
</template>

<script>
export default {
    data: () => ({
        selectedMonth: null,
        selectedYear: null,
        years: [],
        months: [
            { name: "Enero", value: 1 },
            { name: "Febrero", value: 2 },
            { name: "Marzo", value: 3 },
            { name: "Abril", value: 4 },
            { name: "Mayo", value: 5 },
            { name: "Junio", value: 6 },
            { name: "Julio", value: 7 },
            { name: "Agosto", value: 8 },
            { name: "Septiembre", value: 9 },
            { name: "Octubre", value: 10 },
            { name: "Noviembre", value: 11 },
            { name: "Diciembre", value: 12 }
        ]
    }),

    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.mobile;
        }
    },

    methods: {
        onSubmit() {
            this.$emit('filter', this.selectedMonth, this.selectedYear);
        }
    },

    mounted() {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1;
        this.years = [currentYear, currentYear - 1, currentYear - 2];

        this.selectedYear = currentYear;
        this.selectedMonth = currentMonth;
    }
}
</script>

<style lang="scss" scoped>
.date-picker {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;

    .v-btn {
        grid-column: span 2;
    }

    &.isDesktop {
        grid-template-columns: 2fr 2fr 1fr;

        .v-btn {
            grid-column: inherit;
        }
    }
}
</style>