<template>
    <v-container class="px-4 px-lg-9">
        <!-- Title -->
        <v-list-item class="px-0" two-line>
            <v-list-item-content>
                <v-list-item-title class="text-h6">
                    Bienvenido, 
                    <span class="font-weight-bold">{{ $store.state.auth.user.displayName }}</span>
                </v-list-item-title>
                <v-list-item-subtitle class="text-subtitle-1 text-capitalize">{{ today }}</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>

        <!-- Filter -->
        <v-row justify="space-between" class="mt-3 mb-3">
            <v-col cols="12" md="2">
                <v-btn v-if="!company.disabled" @click="dialog = true" color="primary" depressed block>Nuevo Evento</v-btn>
            </v-col>
            <v-col cols="12" md="6" class="text-end">
                <DatePicker v-if="showFilter || !isMobile" @filter="onFilter" />
                <v-btn class="rounded" :class="{ ['mt-4']: showFilter }" @click="showFilter = !showFilter"
                    v-if="isMobile" outlined>
                    <v-icon>{{ showFilter ? 'mdi-filter-off-outline' : 'mdi-filter-outline' }}</v-icon> Filtros
                </v-btn>
            </v-col>
        </v-row>

        <!-- Table -->
        <v-card outlined>
            <v-skeleton-loader v-if="loading" type="table-tbody" />
            <v-simple-table v-else class="rv-table">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">Evento</th>
                            <th class="text-left">Fecha</th>
                            <th class="text-end"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="event, index in events" :key="index" class="font-weight-medium">
                            <td>
                                <router-link :to="{ name: 'MyEvent', params: { uid: event.uid } }">{{ event.name
                                    }}</router-link>
                            </td>
                            <td>{{ event.date }}</td>
                            <td class="text-end">
                                <v-btn icon :loading="event.loading" @click="removeEvent(index, event.uid)" color="red"
                                    text>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="icon icon-tabler icons-tabler-outline icon-tabler-trash">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M4 7l16 0" />
                                        <path d="M10 11l0 6" />
                                        <path d="M14 11l0 6" />
                                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                    </svg>
                                </v-btn>
                            </td>
                        </tr>
                        <tr v-if="!events.length">
                            <td colspan="3" class="text-center">Sin información</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card>

        <!-- Components -->
        <NewEventDialog v-model="dialog" @close="onClose" />
        <v-snackbar v-model="snackbar" color="error">Hubo un problema al eliminar el evento.</v-snackbar>
    </v-container>
</template>

<script>
import moment from 'moment';
import { getEventsByMonth, disabledEvent } from '@/api/events';
import DatePicker from '../components/DatePicker.vue';
import NewEventDialog from "../modules/events/Create.vue";
import { mapState } from 'vuex';

export default {
    components: { DatePicker, NewEventDialog },

    data: () => ({
        today: moment().format('dddd, MMMM DD, YYYY'),
        dialog: false,
        events: [],
        loading: false,
        snackbar: false,
        showFilter: false
    }),

    computed: {
        ...mapState('auth', ['company']),

        isMobile() {
            return this.$vuetify.breakpoint.mobile;
        }
    },

    methods: {
        async fetchEvents(month, year) {
            try {
                this.loading = true;
                this.events = await getEventsByMonth(month, year);
            } catch (error) {
                console.error(error.message)
            } finally {
                this.loading = false;
            }
        },

        async removeEvent(index, item) {
            try {
                this.events[index].loading = true;
                await disabledEvent(item);
                this.events.splice(index, 1);
            } catch (error) {
                this.snackbar = true;
            }
        },

        onFilter(month, year) {
            this.fetchEvents(month, year);
        },

        onClose() {
            this.dialog = false;
            const currentYear = new Date().getFullYear();
            const currentMonth = new Date().getMonth() + 1;

            this.fetchEvents(currentMonth, currentYear)
        }
    },

    mounted() {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1;

        this.fetchEvents(currentMonth, currentYear)
    },
}
</script>

<style lang="scss" scoped></style>