<template>
    <QrcodeStream :track="paintOutline" :camera="camera" @decode="onDetect">
        <v-overlay :value="loading" opacity="0.9" absolute>
            <v-progress-circular indeterminate color="primary" />
        </v-overlay>

        <v-snackbar color="error" v-model="snackbar" timeout="900">
            Código QR no es válido
        </v-snackbar>
    </QrcodeStream>
</template>

<script>
import Rut from "rutjs";
import { QrcodeStream } from "vue-qrcode-reader";

export default {
    components: { QrcodeStream },

    props: ["camera", "loading"],

    data: () => ({
        snackbar: false,
    }),

    methods: {
        onDetect(capture) {
            try {
                const data = new URL(capture).searchParams.get("RUN");

                if (!data) { throw new Error("Código QR inválido") }

                var rut = new Rut(data);

                if (rut.isValid) {
                    this.$emit("input", rut.getNiceRut(false));
                } else {
                    throw new Error("Invalid RUT");
                }
            } catch (error) {
                this.snackbar = true;
                this.$emit("reload")
            }
        },

        paintOutline(detectedCodes, ctx) {
            for (const detectedCode of detectedCodes) {
                const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;

                ctx.strokeStyle = "red";

                ctx.beginPath();
                ctx.moveTo(firstPoint.x, firstPoint.y);
                for (const { x, y } of otherPoints) {
                    ctx.lineTo(x, y);
                }
                ctx.lineTo(firstPoint.x, firstPoint.y);
                ctx.closePath();
                ctx.stroke();
            }
        },
    }
}
</script>