<template>
    <v-btn-toggle v-model="selectedMode" @change="handleMode" class="d-flex" mandatory>
        <v-btn class="flex-grow-1" :value="false">Camara</v-btn>
        <v-btn class="flex-grow-1" :value="true">Escáner</v-btn>
    </v-btn-toggle>
</template>

<script>
export default {
    data: () => ({
        selectedMode: false
    }),
    methods: {
        handleMode() {
            window.localStorage.setItem("scanMode", this.selectedMode);
        }
    },
    mounted() {
        this.selectedMode = window.localStorage.getItem("scanMode") === 'true';
    }
}
</script>