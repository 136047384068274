<template>
    <div class="d-flex justify-space-between align-center">
        <v-list-item>
            <v-list-item-avatar class="logo rounded pa-3" size="44">
                <svg width="260" height="280" viewBox="0 0 260 280" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" fill="#ffffff"
                        d="M7.20157 0C0.798481 0 -2.40817 7.73333 2.11947 12.2562L127.895 137.897C129.243 139.244 131.071 140 132.977 140H252.798C259.201 140 262.408 132.267 257.88 127.744L132.105 2.10283C130.757 0.756421 128.929 0 127.023 0H7.20157ZM2.1196 152.256C-2.40803 147.733 0.798614 140 7.2017 140H127.023C128.929 140 130.757 140.756 132.105 142.103L257.881 267.744C262.408 272.267 259.202 280 252.798 280H132.977C131.071 280 129.243 279.244 127.895 277.897L2.1196 152.256Z" />
                </svg>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title class="font-weight-bold text-h5">
                    {{ $store.state.auth.company.name }}
                </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
                <v-btn @click="closeDrawer" v-if="isMobile" icon>
                    <v-icon color="grey lighten-1">mdi-close</v-icon>
                </v-btn>
            </v-list-item-action>
        </v-list-item>
    </div>
</template>

<script>
export default {
    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.mobile;
        },
        isDark() {
            return this.$vuetify.theme.dark;
        }
    },

    methods: {
        closeDrawer() {
            this.$emit("close");
        },
    },
};  
</script>

<style lang="scss" scoped>
.logo {
    background: rgb(64, 19, 192);
    background: linear-gradient(0deg, rgba(64, 19, 192, 1) 0%, rgba(109, 65, 231, 1) 100%);
}
</style>