import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth } from '@/firebase'


import Panel from '../layouts/Panel.vue'

import LayoutAuth from '../layouts/Auth.vue'


Vue.use(VueRouter)


const ownerRoutes = [
  {
    path: "/bienvenido",
    name: "owner-home",
    meta: { auth: true, owner: true, layout: Panel },
    component: () => import("../views/OwnerHome.vue")
  },
  {
    path: "/equipo",
    name: "Staff",
    meta: { auth: true, owner: true, layout: Panel },
    component: () => import("../views/owner/Staff.vue")
  },
  {
    path: '/asistentes/especiales', // Shared
    name: 'attendees-specials',
    meta: { auth: true, owner: true, layout: Panel },
    // component: () => import('../modules/vip/Home.vue')
    component: () => import('../views/Attendees/VipList.vue')
  },
  {
    path: "/blacklist",
    name: "BlackList",
    meta: { auth: true, owner: true, layout: Panel },
    component: () => import('../views/Attendees/BlackList.vue')
  },
]

const scanRoutes = [
  {
    path: '/control-puerta/:uid', // Scan
    name: 'Scan', meta: { auth: true, scan: true, layout: Panel },
    component: () => import('../views/scan/ScanView.vue')
  },
]

const sharedRoute = [
  {
    path: '/version',
    name: 'version',
    meta: { auth: true, seller: true, owner: true, scan: true, layout: Panel },
    component: () => import("../views/VersionView.vue")
  }
]


const routes = [
  ...scanRoutes,
  ...ownerRoutes,
  ...sharedRoute,

  {
    path: '/mis-eventos',
    name: 'MyEvents',
    meta: { auth: true, seller: true, scan: true, layout: Panel },
    component: () => import("../modules/events/Home.vue")
  },

  // View: Invoice
  {
    path: '/pagos',
    name: 'invoice',
    meta: { auth: true, owner: true, layout: Panel },
    component: () => import("../views/invoice/InvoicesView.vue")
  },


  // * ---------------------------------------------------------------------------------- Public
  {
    path: '/', name: 'Login', meta: { layout: LayoutAuth }, component: () => import('../views/Auth/Login.vue')
    // component: () => import('../views/public/Login.vue')
  }, {
    path: '/recuperar-password',
    name: 'Recovery',
    meta: { layout: LayoutAuth },
    component: () => import('../views/Auth/Recovery.vue')
  },


  // * ---------------------------------------------------------------------------------- Modules: Access

  {
    path: '/acceso/:uid',
    name: 'ScannerEvent',
    meta: { auth: true, scan: true, layout: Panel },
    component: () => import("../modules/access/Scanner.vue")
  },

  // * ---------------------------------------------------------------------------------- modules: events


  {
    path: '/eventos/historial',
    name: 'EventsHistory',
    meta: { auth: true, owner: true, seller: true, layout: Panel },
    component: () => import("../modules/events/History.vue")
  },


  // * ---------------------------------------------------------------------------------- Modules

  {
    path: '/evento/:uid', // Shared
    name: 'MyEvent',
    meta: { auth: true, seller: true, owner: true, scan: true, layout: Panel },
    component: () => import("../modules/events/Detail.vue")
  },
]

const router = new VueRouter({
  mode: 'history', base: process.env.BASE_URL, routes
})

router.beforeEach((to, from, next) => {


  auth.onAuthStateChanged(userAuth => {

    if (userAuth) {
      auth.currentUser.getIdTokenResult()
        .then(function ({
          claims
        }) {

          if (claims.admin) {
            if (to.matched.some(record => record.meta.admin)) {
              next()
            } else {
              console.debug("admin")
              // return next({ name: 'Admin' })
            }
          } else if (claims.owner) {
            if (to.matched.some(record => record.meta.owner)) {
              next()
            } else {
              return next({ name: 'owner-home' })
            }
          } else if (claims.scan) {
            if (to.matched.some(record => record.meta.scan)) {
              next()
            } else {
              return next({ name: 'MyEvents' })
            }
          } else {
            if (to.matched.some(record => record.meta.seller)) {
              next()
            } else {
              return next({ name: 'MyEvents' })
            }
          }

        })
    } else {
      if (to.matched.some(record => record.meta.auth)) {
        next({
          name: 'Login', query: {
            redirect: to.fullPath
          }
        })
      } else {
        next()
      }
    }
  })
  next()

})

router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router