import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import events from "./modules/events";
import attendees from "./modules/attendees";
import staff from "./modules/staff";

// Modules
import lists from "./modules/lists";

Vue.use(Vuex);


export default new Vuex.Store({
  state: {
    desktop: false
  },

  mutations: {

    set_desktop(state, payload) {
      state.desktop = payload;
    }
  },


  modules: {
    auth,
    events,
    attendees,
    staff,
    lists,
  },
});
